module.exports = {
    cdn: '',
    trustMode: false,
    serviceCache: {
        host: 'localhost',
        port: 3000
    },
    uniOrders: {
        url: 'https://uni-orders-jeff-tian.cloud.okteto.net',
    }
};